import React, { useState, useRef, useEffect } from 'react';
import RaList from '../../../components/ra-list/RaList';
import RenderControls from '../../../components/ra-list/RenderControls';
import { FunctionField, TextField, Datagrid, useStore } from 'react-admin';
import { dayjs as mydayjs } from '../../../components/ex_dayjs';
import OrderDetails from './component/order-details';
import OrderNotes from './component/order-notes';
import { dataProvider } from '../../../DataProvider';
import { getDefaultUtcOffset, loadSourceType } from '../../../utils/common';
import { DeliveryMethodColors, DeliveryMethodEnum, STATUS_ENUM, Shipping, getTagsColor } from '../../../utils/enum';
import './css/style.css';
import { Tooltip, TextField as MuiTextField } from '@mui/material';
import StatusField from './component/status-field';
import { historicalTags, bus_events } from './vendor';
import { ODatagridHeader } from './controls/ODatagridHeader';
import { initSocketIO as createUI } from '../lib/uiHighlighter';

const OrderBase = (props) => {
    const { vendor } = props;
    const [expandedIds, setExpandedId] = useStore(`${vendor.controller}.datagrid.expanded`, []);//get ids of expanded row by store name
    const refFilter = useRef();
    const [sortBy] = useState(['order_number desc']);
    const [defaultValueFilter, setDefaultValuesFilter] = useState({
        utc_offset_eq: getDefaultUtcOffset(),
        order_number_eq: 67140330 
    });
    const [filterConfig, setFilterConfig] = useState({ disabledAll: false });
    const [orderAccounts, setOrderAccounts] = useState([]);
    const [marketType, setMarketType] = useState([]);
    const [assignedTo, setAssignedTo] = useState([]);
    const [allowStatuses] = useState(vendor.getStatuses());
    const [tags, setTags] = useState(vendor.getTags());
    const [processingState, setProcessingState] = useState({ haveReceipt: false, primary_order_number: '', validatePrimaryTimeout: null });
    const [excluded, setExcluded] = useState([
        { name: 'exclude_ht', label: 'Hard Tickets' },
        { name: 'exclude_wc', label: 'Will Call' },
        { name: 'exclude_rs', label: 'Resale' },
        { name: 'exclude_pp', label: 'Postponed' },
        { name: 'exclude_dnp', label: 'Do Not Process' },
        { name: 'exclude_wts', label: 'Wait To Ship' },
        { name: 'exclude_ntr', label: 'Needs to be refunded' },
        { name: 'exclude_deli', label: 'Delivery' }
    ]);
    const [appIO, setAppIO] = useState({ bus: {}, specificVendor: null, options: {}, bus_events: bus_events, registedBus: false });

    const [alertData, setAlertData] = useState({
        isAlert: false,
        showOrderLastChangedBy: undefined,
        currentOrder: null
    });

    React.useEffect(() => {
        // Loading order accounts       
        dataProvider
            .fetchData(vendor.controller, 'GetOrderAccounts')
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    let arr = response.data.map(x => ({ id: x.id, name: x.spec_alias }));
                    setOrderAccounts(arr);
                }
            })
            .catch(console.error);
        // Loading market type
        loadSourceType(dataProvider, res => setMarketType(res), false);
        // Loading current user | assignedTo      
        dataProvider.currentUser().then((user) => {
            if (user.email) {
                setFilterConfig(prev => ({ ...prev, currUser: user.email }));
                dataProvider.fetchData(vendor.controller, `GetUsersWorkingToday?email=${user.email}&utcOffset=${defaultValueFilter.utc_offset_eq}`)
                    .then((response) => {
                        if (response.status !== 200 && response.data) {
                            let sample = [{ id: '-1', name: 'ANY' }, { id: '0', name: 'General' }];
                            let arr = sample.concat(response.data.map(x => ({ id: x.email + '', name: x.aliasName })));
                            setAssignedTo(arr);
                        }
                    })
                    .catch(console.error);
            }
        });
        //load order tags
        dataProvider.fetchData(vendor.controller, 'GetOrderTags')
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    response.data.forEach((tag) => {
                        if (!historicalTags.some(ht => ht.toLowerCase() == tag.toLowerCase()) && !tags.some(t => t.toLowerCase() == tag.toLowerCase())) {
                            tags.push(tag);
                        }
                    });
                    setTags(tags);
                } else {
                    console.error('Load Order Tags Failed');
                }
            })
            .catch(console.error);
    }, []);

    function RegisterIOBus() {
        //New IO-Highlight
        let bus = vendor.bus;

        //#region New-IO
        const IO_Options = {
            options: {
                // ioUrl: "collab.songtek.net",
                ioUrl: "localhost:8083",
                env: {
                    Identity: filterConfig.currUser,
                    FirstName: 'Toan'
                }
            },
            bus_events: bus_events,
            bus: bus,
            vendor: vendor
        };

        setAppIO(IO_Options);
        createUI(IO_Options);

        //#endregion

        //Grid-bus
        const createGridBus = () => {
        //#region Grid-bus
            bus.on(bus_events.order_saved, (data) => updateGridRow(data, "order_saved"));
            bus.on(bus_events.order_note_saved, (data) => updateGridRow(data, "order_note_saved"));
            bus.on(bus_events.grid_order_shown, (data) => updateGridRowByOrder(data, "grid_order_shown"));
            //#endregion

            function updateGridRowByOrder(order) {
                console.log(`*** updateGridRowByOrder`);
                let notes = order.order_notes ?? [];
                order.order_notes = notes.reduce(function (prev, cur) { if (prev.length < 3 && cur.note_type == 0) prev.push(cur); return prev; }, []);
                order.order_fullnotes = notes;

                bus.emit(bus_events.refresh_highlight_box, order);
            }

            function updateGridRow(data, prefix) {
                console.log(`*** updateGridRow - ${prefix}`);
                var order = data.reduce((prev, curr) => { prev[curr.name] = curr.value; return prev; }, {});
                updateGridRowByOrder(order);
            }
        };

        //Editor-bus
        const createEditorBus = () => {
            console.log('Need to register editor-bus');
            //#region BUS

            //this event was fired before the dom removed, that keeps the datetime pickers and other object attached
            bus.on(bus_events.grid_selection_changed, function (data) {
                setAlertData((prev) => ({ ...prev, isAlert: false, currentOrder: data }));
                console.log(`grid_selection_changed`, data);
            });
            bus.on(bus_events.order_status_changed, function (data, by) {
                console.log(`order_status_changed`, data, by);
                var order = data;
                if (data.reduce != undefined)
                    order = data.reduce((prev, curr) => { prev[curr.name] = curr.value; return prev; }, {});

                var id = order.ID ?? order.id; //Combine 2 versions
                showAlert(id, order.processor);
            });

            bus.on(bus_events.order_saved, function (data, by) {
                var order = data.reduce((prev, curr) => { prev[curr.name] = curr.value; return prev; }, {});
                var id = order.ID ?? order.id; //Combine 2 versions
                console.log(`order_saved`, by, id, data, order);
                showAlert(id, order.processor);
            });

            bus.on(bus_events.order_note_saved, function (data, by) {
                console.log(`order_note_saved`, data, by);
                var order = data.reduce((prev, curr) => { prev[curr.name] = curr.value; return prev; }, {});
            });

            //#endregion

            function showAlert(id, by) {
                var orderId = window.currentOrder.id;
                if (orderId == id && filterConfig.currUser != by) {
                    setAlertData((prev) => ({ ...prev, isAlert: true, showOrderLastChangedBy: by }));
                }
            }
        };

        createGridBus();
        createEditorBus();
    }

    React.useEffect(() => {
        if (filterConfig.currUser != null) {
            RegisterIOBus();
        }
    }, filterConfig.currUser);

    useEffect(() => {
        if (defaultValueFilter.order_number_eq) setFilterConfig(prev => ({ ...prev, disabledAll: true }));
    }, [defaultValueFilter]);

    const handleOrderNumberFilter = (props) => {
        if (props.value) setFilterConfig(prev => ({ ...prev, disabledAll: true }));
        else setFilterConfig(prev => ({ ...prev, disabledAll: false }));
    };

    const controls = [
        { component: 'number', name: 'order_number_eq', fullWidth: false, xs: 1, label: 'Order #', onChange: handleOrderNumberFilter },
        { component: 'select', className: 'mselect-small', name: 'vivid_account_id_eq', label: 'Account', choices: orderAccounts || [], disabled: filterConfig?.disabledAll },
        { component: 'select', className: 'mselect-small', name: 'internal_status_eq', label: 'Status', choices: allowStatuses || [], xs: 2, disabled: filterConfig?.disabledAll },
        { component: 'daterange', name: 'order_date', label: 'Order Date', xs: 2, disabled: filterConfig?.disabledAll },
        { component: 'daterange', name: 'date', label: 'Event Date', xs: 2, disabled: filterConfig?.disabledAll },
        { component: 'text', name: 'notes', label: 'Order Notes', xs: 3.5, disabled: filterConfig?.disabledAll },
        { component: 'text', name: 'event', label: 'Event', xs: 2, disabled: filterConfig?.disabledAll },
        { component: 'text', name: 'venue', label: 'Venue', xs: 2, disabled: filterConfig?.disabledAll },
        { component: 'select', className: 'mselect-small', name: 'delivery_method_eq', label: 'Delivery Method', choices: DeliveryMethodEnum.map(x => ({ ...x, id: x.value })) || [], disabled: filterConfig?.disabledAll },
        { component: 'select', className: 'mselect-small', name: 'event_market_eq', label: 'Market Type', choices: marketType || [], disabled: filterConfig?.disabledAll },
        { component: 'autocomplete', id: "mautocomplete", name: 'tags_eq', label: 'Tags', choices: tags || [], getStyles: getTagsColor, disabled: filterConfig?.disabledAll, variant: 'filled' },
        { component: 'checkboxgroup', name: 'exclude_eq', data: excluded, label: 'Exclude', xs: 4, disabled: filterConfig?.disabledAll },
        { component: 'daterange', name: 'checkback_date', label: 'Check back date', disabled: filterConfig?.disabledAll },
        { component: 'daterange', name: 'in_hand_date', label: 'Inhand Date', disabled: filterConfig?.disabledAll },
        { component: 'select', className: 'mselect-small', name: 'shipping', label: 'Shipping', choices: Shipping, xs: 1, isHide: vendor.hideShippingFilter(), disabled: filterConfig?.disabledAll },
        { component: 'select', className: 'mselect-small', name: 'assigned_to_eq', label: 'Assigned To', choices: assignedTo || [], xs: vendor.hideShippingFilter() ? 2 : 1, disabled: filterConfig?.disabledAll },
        { component: 'text', name: 'primary_order_number_eq', label: 'Primary Order Number', placeholder: '10-32454/NCA', disabled: filterConfig?.disabledAll },
        { component: 'text', name: 'primary_account_contains', label: 'Primary Account', placeholder: 'jennfer.lynn4926@gmail.com', disabled: filterConfig?.disabledAll },
        { component: 'text', name: 'utc_offset_eq', isHide: true },
    ];

    const handleInputPrimaryOrderNo = (props2, record) => {
        let valueInput = props2.target.value;
        var validatePrimaryTimeout;
        var previousRequest = { reject: () => { } };

        window.clearTimeout(validatePrimaryTimeout);
        previousRequest.reject();

        record.primary_account = '';
        record.primary_order_number = valueInput;

        setProcessingState(prev => ({ ...prev, haveReceipt: false }));//force re-render row

        record.primary_cost = '';
        record.penalty_cost = '';

        if (valueInput === '') {
            return;
        }

        validatePrimaryTimeout = window.setTimeout(function () {
            var cancellation = new Promise(function (resolve, reject) {
                previousRequest.reject = reject;
            });

            var queryData = dataProvider.fetchData(vendor.controller, 'SearchPrimary?receiptNo=' + valueInput);

            Promise.race([cancellation, queryData])
                .then(function (json) {
                    previousRequest.reject = () => { };
                    if (!json.data) {
                        setProcessingState(prev => ({ ...prev, haveReceipt: false }));
                        return;
                    }

                    record.primary_cost = json.data.TixPrice;
                    record.primary_account = json.MailUser;
                    // bus.emit(bus_events.receipt_available, json);

                    setProcessingState(prev => ({ ...prev, haveReceipt: true }));
                },
                    function () {
                        previousRequest.reject = () => { };
                    });
        }, 500);

    };

    return (
        <div id='order-page'>
            <RaList
                title={vendor.getTitle()}
                sortby={sortBy}
                perPage={50}
                pagination={[25, 50, 250, 500, 1000]}
                filters={
                    <RenderControls
                        refFilter={refFilter}
                        filterInline={false}
                        controls={controls}
                        defaultValues={defaultValueFilter}
                        beforeSearch={() => {
                            setExpandedId([]);
                            appIO.bus.emit(bus_events.grid_before_send);
                        }}
                    />
                }
            >
                <Datagrid bulkActionButtons={false}
                    expand={<OrderDetails reload={setExpandedId} appIO={appIO} accounts={orderAccounts} currUser={filterConfig.currUser} alertData={alertData} setAlertData={setAlertData} />}
                    expandSingle rowClick='expand'
                    isRowExpandable={(row) => {
                        //console.log(`_row expanded`, row);
                        return true;
                    }}
                //rowSx={rowStyle}
                    header={<ODatagridHeader vendor={vendor} />}
                >
                    <FunctionField source="vivid_account_id" label={'ACC'} render={record => orderAccounts.find(acc => acc.id == record.vivid_account_id)?.name} />
                    <TextField source="order_number" label={'Order #'} />
                    <FunctionField label='Order Date' source='order_date' render={record => (mydayjs(record.order_date).format('MMM DD, YYYY'))} />
                    <FunctionField label='CB Date' source='checkback_date' render={record => (
                        record.checkback_date ? mydayjs(record.order_date).format('MMM DD, YYYY') : '')
                    } />
                    <FunctionField label='Event' source='event-venue-order_total' render={record => vendor.buildEventBlock(record)} sortBy={'event'} />
                    <FunctionField label='Event Date' source='date' render={record => (
                        <>{mydayjs(record.date).format('MMM DD, YYYY')}<br />{mydayjs(record.date).format('hh:mm A')}</>)}
                    />

                    <FunctionField source="primary_order_number-primary_account" label={'Primary #'} render={record =>
                        <Tooltip title={`${record.primary_order_number} ${record.primary_account}`}>
                            <span>
                                {record.internal_status == STATUS_ENUM.Processing && record.processor == filterConfig.currUser &&
                                    <MuiTextField variant='outlined' size='small' margin='none' placeholder='order id...' sx={{ fontSize: '12px', borderColor: !processingState.haveReceipt ? 'red' : '' }}
                                        onChange={(props) => handleInputPrimaryOrderNo(props, record)} value={record.primary_order_number} />
                                    ||
                                    (record.link_to_print_tickets && <a href={record.link_to_print_tickets} target='_blank'>{record.primary_order_number}</a> || <span>{record.primary_order_number}</span>)
                                }
                                <br /><span style={{ fontSize: '12px' }}>{record.primary_account}</span>
                            </span>
                        </Tooltip>
                    } />
                    <FunctionField source="primary_cost" label={'Cost'} textAlign='right' render={record => (
                        <span>{record.primary_cost ? `$${record.primary_cost}` : ''}{record.penalty_cost ? `($${record.penalty_cost})` : ''}</span>
                    )} />
                    <FunctionField label='Inhand Date' source='in_hand_date' render={record => {
                        let dvMethod = DeliveryMethodColors.find(x => x.id == record.delivery_method);
                        let currentDatePST = mydayjs().tz('America/Los_Angeles');
                        let daysToInHand = mydayjs(record.in_hand_date).tz('America/Los_Angeles').diff(currentDatePST, 'day');
                        let methodNameColor = daysToInHand < 2 ? '#d9534f' : daysToInHand < 5 ? '#f0ad4f' : '';
                        return (
                            <>
                                <span style={{ backgroundColor: dvMethod?.color, fontSize: '11px' }} className='custom-Tag'>{dvMethod?.name || "PDF"}</span>
                                {daysToInHand < 5 && <span className='custom-Tag' style={{ backgroundColor: methodNameColor, marginLeft: '2px' }}>{mydayjs(record.in_hand_date).format('MMM DD, YYYY')}</span>
                                    || <span style={{ marginLeft: '2px' }}>{mydayjs(record.in_hand_date).format('MMM DD, YYYY')}</span>}
                            </>
                        );
                    }} />
                    <StatusField label='Status' appIO={appIO} source='internal_status-delivery_method' mydayjs={mydayjs} currUser={filterConfig.currUser} processingState={processingState} dataProvider={dataProvider} />
                    <OrderNotes label="Notes" source='order_notes' />
                </Datagrid>
            </RaList >
        </div>

    );
};

export default OrderBase;