import React, { useState, useRef } from 'react';
import MDialog from '../../../components/controls/MDialog';
import MAutoComplete from '../../../components/controls/MAutoComplete';
import { Stack, FormControlLabel, Button, LinearProgress, Divider, Checkbox, TextField, Box, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { useNotify, Confirm } from "react-admin";
import PreviewIcon from '@mui/icons-material/Preview';
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import getSQL from '../../../components/query-builder/common';
import { reloadPage } from '../../../utils/common';
import RuleCreator from './rule-creator';
import MNumber from '../../../components/controls/MNumber';
import { MLabel } from '../../csv-merge/components/RuleEditPopup';

const EditRule = ({ dataProvider, closeDialog, refbtnFilter, exchanges, ...props }) => {
    const { open, record, isEdit, resource } = props.info;
    const { columnDefs, conditionFields, actionFields, initialState } = props.ruleInfo;
    const [openConfirm, setOpenConfirm] = useState(false)
    const [ruleName, setRuleName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(null);
    const [valid, setValid] = useState(true);
    const [previewData, setPreviewData] = useState([]);
    const [applyFor, setApplyFor] = useState(null);
    const [priority, setPriority] = useState(1);
    const [disable, setDisable] = useState(false);//for display MBackDrop
    const notify = useNotify();
    const actionQuery = useRef();
    const conditionQuery = useRef();
    const apiRef = useGridApiRef();

    React.useEffect(() => {
        if (isEdit) {
            setRuleName(record.name);
            setActive(record.active);
        } else {
            setRuleName('');
            setActive(true);
        }
        setPreviewData([]);
        setValid(true);
    }, [record]);

    const handleRuleNameChange = (ev) => {
        setRuleName(ev.target.value);
    }
    const handleActive = (ev) => {
        setActive(ev.target.checked);
    }
    const SaveRule = (_action) => {
        let data = getData();
        if (!isValidData(data)) return;

        if (_action == 'delete') {
            data.name = '';
            setOpenConfirm(false);
        }

        setDisable(true);
        dataProvider.postData('DynamicAdjustRule', 'Save', data)
            .then((response) => {
                setDisable(false);
                setApplyFor(null);
                if (response?.data) {//data = rule_name
                    if (isEdit)
                        notify('Update Success', { type: 'success', autoHideDuration: 5000 });
                    else
                        notify('Create Success', { type: 'success', autoHideDuration: 5000 });

                } else {
                    if (response.message)
                        notify(`Error: ${response?.message}`, { type: 'error', autoHideDuration: 5000 });
                    else if (response.data == '' && _action == 'delete') {
                        notify('Delete Success', { type: 'success', autoHideDuration: 5000 });
                    }
                    else
                        notify(`Error happened. Try to reload page. ${response}`, { type: 'error', autoHideDuration: 5000 });
                }
                closeDialog();
                reloadPage(resource || 'DynamicAdjustRule', refbtnFilter);
            })
            .catch(err => {
                notify(`Something went wrong. ${err}`, { type: 'error', autoHideDuration: 5000 });
            });
    }
    const handleDelete = () => {
        setOpenConfirm(true);
    }

    const isValidData = (data) => {
        if (data == null) {
            notify(`Please check your input!!!`, { type: 'error', autoHideDuration: 3000 });
            return false;
        }
        setValid(true);
        if (data.condition == null) {
            notify(`Require rule!!!`, { type: 'error', autoHideDuration: 3000 });
            return false;
        }
        if (data.action == null || !data.action.includes('{')) {
            notify(data.action || `Require action!!!`, { type: 'error', autoHideDuration: 3000 });
            return false;
        }

        return true;
    }
    const showMatchCondition = () => {
        let data = getData();
        if (!isValidData(data)) return;

        setIsLoading(true);
        dataProvider.postData('DynamicAdjustRule', 'Preview', data)
            .then((response) => {
                if (response.data) {
                    let arr = displayData(response.data);
                    notify(`Found ${arr.length} candidates`, { type: 'success', autoHideDuration: 5000 });
                    // Check the columns is have value then show it
                    if (initialState.hideColumns) {
                        let hideColumns = {};
                        Object.keys(initialState.hideColumns).map((col) => {
                            hideColumns[col] = arr.some(e => e[col]) || false;
                        });
                        apiRef.current.setColumnVisibilityModel(hideColumns);
                    }
                    setPreviewData(arr);
                }
                else {
                    notify(`Something went wrong. Status: ${response.status}`, { type: 'error', autoHideDuration: 5000 });
                }
                setIsLoading(false);
            })
            .catch(err => {
                notify(`Error !!! . Status: ${err.status}`, { type: 'error', autoHideDuration: 5000 });
                setIsLoading(false);
            });

    }
    const displayData = (data) => {
        return data.map(x => {
            x.name = x.rename_Event || x.name;
            x.venue = x.rename_Venue || x.venue;
            x.city = x.rename_City || x.city;
            x.state = x.rename_State || x.state;
            return x;
        });
    }

    const getData = () => {
        if (!!!ruleName || ruleName === '') {
            setValid(false);
            return null;
        }
        let actionJson = actionQuery.current.getValues('json');
        if (actionJson) {
            let requireActionFieldIds = actionFields.filter(x => x.required).map(x => x.id);
            if (requireActionFieldIds.length) {
                let tmp = JSON.parse(actionJson);
                let inputedValue = tmp.rules.filter(x => requireActionFieldIds.some(y => y == x.id)).map(x => x.id);
                if (!requireActionFieldIds.every(element => inputedValue.includes(element))) {
                    actionJson = `Require rules: ${requireActionFieldIds.join()}`;
                }
            }
        }
        let conditionJson = conditionQuery.current.getValues('json');
        return {
            id: record.id || '0',
            categoryId: isEdit ? record.categoryId : Number(document.querySelector('[name="categoryId"]').value),
            applyfor: applyFor == null ? record.applyfor?.split(',').filter(e => e != '').join(',') : applyFor.map(x => x.id).join(','),
            priority: priority,
            active: active,
            name: ruleName || '',
            action: actionJson,
            condition: conditionJson,
            sqlCondition: conditionJson ? getSQL(JSON.parse(conditionJson)) : conditionJson,
            createdby: record?.createdby || '',
            modified: record?.modified
        }
    }

    const displayExchange = () => {
        let exValues = record?.applyfor?.split(',').filter(e => e != '');
        if (!!!exValues)
            return exValues;
        return exchanges.filter(obj => exValues.some(e => e.includes(obj.id)));
    }

    return (
        <>
            <MDialog
                maxWidth={"lg"}
                open={open}
                handleClose={closeDialog}
                title={isEdit ? `#${record.id}` : `New Rule`}
                name={'edit-rule-dialoge'}
                disabled={disable}
                action={
                    <Stack direction="row" justifyContent={"space-between"} width="100%">
                        <FormControlLabel label="Active" control={<Checkbox defaultChecked={!isEdit ? true : record.active} checked={active} onChange={handleActive} />} />
                        <Stack direction="row" spacing={1} justifyContent={'flex-end'}>
                            <Button startIcon={<SendIcon />} color='info' variant='contained' onClick={SaveRule}>Save</Button>
                            <Button startIcon={<DeleteIcon />} color={'error'} variant='contained' onClick={handleDelete}>Delete</Button>
                        </Stack>
                    </Stack>
                }
                children={
                    <div>
                        <Divider></Divider>
                        <MLabel key={'name'} name={'Rule Name'} />
                        <TextField
                            value={ruleName ?? ''}
                            fullWidth
                            required
                            error={!valid}
                            helperText={valid ? '' : "Rule name can not be empty"}
                            label="Rule"
                            onChange={handleRuleNameChange}
                            placeholder="eg. Ban venue abc..." variant="outlined" />

                        <Grid container spacing={2} >
                            <Grid item xs={8}>
                                <MLabel key={'applyfor'} name={'Apply For'} />
                                <MAutoComplete
                                    name="applyfor"
                                    variant='outlined'
                                    value={displayExchange || ''}
                                    choices={exchanges}
                                    onChange={(values) => setApplyFor(values)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MLabel key={'priority'} name={'Priority'} />
                                <MNumber
                                    variant="outlined"
                                    value={record?.priority || 1}
                                    onChange={(e) => setPriority(e)} />
                            </Grid>
                        </Grid>

                        <RuleCreator
                            isEdit={isEdit}
                            editData={record}
                            conditionQueryRef={conditionQuery}
                            actionQueryRef={actionQuery}
                            conditionFields={conditionFields}
                            actionFields={actionFields} />
                        <br />

                        <Button startIcon={<PreviewIcon />} color='info' variant='contained' onClick={showMatchCondition}>Preview</Button>

                        <MLabel key={'math'} name={'Match Candidates'} h={'h6'} />
                        {isLoading && <LinearProgress color="secondary" />}
                        <Box sx={{ height: previewData.length > 0 ? "60vh" : 100, width: '100%' }}>
                            <DataGrid
                                apiRef={apiRef}
                                rows={previewData}
                                columns={columnDefs}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                    columns: {
                                        columnVisibilityModel: initialState?.hideColumns || {}
                                    },
                                    sorting: {
                                        sortModel: initialState?.sortModel
                                    }
                                }}
                            />
                        </Box>
                    </div>
                }
            />
            <Confirm isOpen={openConfirm} content={`Delete rule #${ruleName}. Are you sure?`} title={'Delete rule'} onConfirm={() => SaveRule('delete')} onClose={() => setOpenConfirm(false)} />
        </>
    )
}

export default React.memo(EditRule);