import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, Typography, InputAdornment, IconButton, Alert, LinearProgress, CircularProgress } from "@mui/material";
import { Storage, FmdBadOutlined, SavedSearchOutlined } from "@mui/icons-material";
import { useRecordContext, useNotify, useDataProvider, Confirm } from "react-admin";
import { DeliveryMethodEnum, STATUS_ENUM, deliveryCongig, getTagsColor } from "../../../../utils/enum";
import dayjs from "dayjs";
import MDatePicker from "../../../../components/controls/MDatePicker";
import MAutoComplete from "../../../../components/controls/MAutoComplete";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import MSelect from "../../../../components/controls/MSelect";
import OrderNotesDetails from "./order-notes-details";
import OrderShipping from "./shipping";
import DeliveryDelayPopup from "./dialogs/delivery-delay";
import SuggestAccountsPopup from "./dialogs/suggest-accounts";
import TrackingMappingPopup, { onConfigTrackingMapping } from "./dialogs/tracking-mapping";
import OrderProcessTrackingPopup, { onConfigTrackingProcess } from "./dialogs/order-process-tracking";
import MoreDetailPopup from "./dialogs/more-detail";
import DMQPopup from "./dialogs/dmq";
import MCheckbox from "../../../../components/controls/MCheckbox";
import MNumber from "../../../../components/controls/MNumber";

const OrderDetails = React.memo((props) => {
    const { accounts, currUser, appIO, alertData, setAlertData, reload } = props;
    const { vendor, bus, bus_events } = appIO;
    let { isAlert, showOrderLastChangedBy } = alertData;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const record = useRecordContext();
    if (!record) return null;

    const USD2CAD = 0.8;
    const [forceRender, setForceRender] = useState({
        isWillCall: false,
        isAvailableUnshipped: false,
        isHardTicket: false
    });
    const [initialOrder, setInitialOrder] = useState(null);
    const [notes, setNotes] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const allowEditStatuses = vendor.getEditableStatuses();
    const tags = vendor.getTags();
    const [dlgState, setDlgState] = useState({
        // for delivery delay dialog
        d_isOpen: false,
        d_isEnable: false,
        d_data: {},
        // for tracking mapping dialog
        m_isOpen: false,
        m_data: {},
        // for tracking history dialog
        h_isOpen: false,
        h_isEnable: false,
        h_isSaveOrder: false,
        // for DMQ dialog
        dmq_isOpen: false,
        // for suggest accts dialog
        s_isOpen: false,
        // for More dialog
        more_isOpen: false,
        // for validate form
        open: false,
        content: [], // warning content 
        control: '', // specify control to do the next steps
        openShipping: false, //for opening Shipping popup or not
        sendEmail: true, // notify changed or not. Default is sending email if change Delivery Method
        // for callback function
        callback: null
    });

    const [isSaving, setSaving] = useState(false);

    React.useEffect(() => {
        setIsLoading(true);

        //Set highlight
        window.currentOrder = record;
        bus.emit(bus_events.grid_selection_changed, record);
        bus.emit(bus_events.grid_order_shown, record, record.order_number);

        //get record
        dataProvider.fetchData(vendor.controller, `Get?orderNumber=${record.order_number}`)
            .then(rs => {
                setIsLoading(false);
                const order = rs.data;
                if (order) {
                    updateRecord(order);
                    setInitialOrder(order);//create oldORDER + force render for new record
                    setCustomer(order.customer);
                }
            })
            .catch(error => {
                notify(`Load record failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 2000 });
            }).finally(_ => setIsLoading(false));

        //get notes
        loadNotes();

        function updateRecord(data) {
            Object.keys(data).forEach(key => {
                if (key == 'order_notes') record[key] = data[key]?.slice(0, 3);
                else record[key] = data[key];
            });
        }
    }, []);

    useEffect(() => {
        if (initialOrder && record) config();
    }, [record, initialOrder]);

    function loadNotes() {
        let funcLoadNote = (vendor.checkIfNeedSyncNote() ?? false) ? 'NotesWithSync' : 'Notes';
        dataProvider.fetchData(vendor.controller, `${funcLoadNote}/?order_id=${record.id}`)
            .then(rs => {
                if (rs.data) {
                    setNotes(rs.data.map((x, index) => { x.id = index + 1; return x; }));
                }
            })
            .catch(error => {
                notify(`Load notes failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 2000 });
            });
    }
    /**
     * Config to show/hide a control, set state, other,...
     */
    function config() {
        // config show/hide control
        setForceRender((prev) => ({
            ...prev,
            isAvailableUnshipped: isAvailableUnshipped(),
            isHardTicket: isHardTicket(),
            isWillCall: isWillCall(),
        }));
        // config delivery delay
        toggleDeliveryDelayBtn();
        // config process tracking
        let _config = onConfigTrackingProcess(initialOrder, record.internal_status, notes);
        if (_config) setDlgState((prev) => ({ ...prev, h_isEnable: _config.isShow, h_isSaveOrder: false }));
    }

    //#region For dialogs

    const onCloseDialog = (event, dialogName, isReject = true) => {
        let name = event?.currentTarget?.ariaLabel || dialogName;
        setDlgState((prev) => ({ ...prev, [name]: false, content: name == 'open' ? [] : prev.content }));
        if (isReject) setSaving(false); // close dialog without saving
    };

    function isAvailableUnshipped() { return record?.internal_status == 1 }
    function isHardTicket() { return record?.delivery_method == 1 }
    function isWillCall() { return record?.delivery_method == 6 }

    function isDelayDateInvalid() {
        var delayDate = dayjs(record?.delay_date);
        var inhandDate = dayjs(record?.in_hand_date);
        return delayDate.isAfter(inhandDate);
    }

    function toggleDeliveryDelayBtn() {
        if (!isHardTicket() && isAvailableUnshipped()) {
            setDlgState((prev) => ({ ...prev, d_isEnable: true }));
        } else {
            setDlgState((prev) => ({ ...prev, d_isEnable: false }));
        }
    }

    const onClickDeliveryDelay = (val, name) => {
        // Set delay dete
        let eventDate = dayjs(record.date);
        let delayDate = eventDate.subtract(val || 0, 'days');
        setDlgState((prev) => ({ ...prev, [name]: true, d_data: { delay_date: delayDate.format('MM/DD/YYYY'), days: val } }));
        onChangeOrder('delay_date', delayDate.format('YYYY-MM-DDT00:00:00'));
    }

    const sharedDialogProps = {
        vendor,
        dataProvider,
        data: { order: record, dlgState },
        onClose: onCloseDialog
    };
    //#endregion

    //#region validating
    function SkipNotify(exchange, from, to) {
        let delivery_skipped = exchange.delivery_skipped, delivery_valid = exchange.delivery_valid;
        from = parseInt(from), to = parseInt(to);
        //Check Valid
        if (!!delivery_valid) {
            let valid = delivery_valid.some(s => (s.from_to[0] == -1 || s.from_to[0] == from) && s.from_to[1] == to);
            console.log(`Valid [${from}, ${to}]: ` + valid);
            if (!valid) return !valid;
        }

        //Check Skipped
        return delivery_skipped.some(s => (s.to == to && s.from.some(f => f == from)) ||
            (!!s.parallel && s.from_to.every(d => [from, to].includes(d))));
    }
    function warnPurchase() {
        return record.purchase_section?.toLowerCase() != record.section?.toLowerCase() || record.purchase_row != record.row || record.purchase_quantity != record.quantity;
    }
    const validateDialogFixed = (control) => {
        let displayed = false;

        //Support VV/TN
        //delivery method changed
        let curOrder = initialOrder;
        let deliveryMethod = record.delivery_method_value;
        let oldDeliveryMethod = curOrder.delivery_method_value;

        let exchange = deliveryCongig.EXCHANGEs.find(s => s.market_id == curOrder.market_id);
        if (!!exchange && oldDeliveryMethod != deliveryMethod) {
            var message = `Send an email to portal (change from ${deliveryCongig.DELIVERY_MAP[oldDeliveryMethod]} to ${deliveryCongig.DELIVERY_MAP[deliveryMethod]})?   `;
            if (!SkipNotify(exchange, oldDeliveryMethod, deliveryMethod)) {
                displayed = true;
                setDlgState(prev => ({
                    ...prev, content: [...prev.content,
                    <li style={{ color: 'red' }}>
                        {message}<MCheckbox value={dlgState.sendEmail} id='sendEmail' onChange={(value) => setDlgState(prev => ({ ...prev, sendEmail: value }))} />
                    </li>
                    ]
                }))
            }
            else console.log(`Skipped! ${message}`);
        }
        //cost changed
        var cost = record.primary_cost;
        var orderTotal = curOrder.order_total;
        if (!!cost && parseFloat(cost) > parseFloat(orderTotal)) {
            displayed = true;
            let overPPE = parseFloat(cost) > parseFloat(orderTotal) * 1.5;
            var message = `Primary cost too high. PPE ${(overPPE ? 'over' : 'under')} 1.5.`;

            setDlgState(prev => ({
                ...prev, content: [...prev.content,
                <li style={{ color: overPPE ? 'red' : '' }}>{message}</li>
                ]
            }))
        }
        else {
            var internal_status = curOrder.internal_status;
            if (internal_status == STATUS_ENUM.PendingShipment) {
                if (warnPurchase()) {
                    displayed = true;
                    setDlgState(prev => ({
                        ...prev, content: [...prev.content,
                        <li>Section, row don't match with record</li>
                        ]
                    }))
                }
            }
        }
        setDlgState(prev => ({ ...prev, open: displayed, control: control }));
        return displayed;
    };

    function validPurchase() {
        let event_market = record.event_market || '';
        let section = record.section;
        let row = record.row
        let validateFields = [
            'primary_order_number',
            'primary_account',
            'primary_cost',
            'purchase_section',
            'purchase_quantity'
        ];

        if (event_market.toLowerCase() === 'exact') {
            validateFields.push('link_to_print_tickets');
        }

        if (section.toUpperCase() != 'GA' && row.toUpperCase() != 'GA') {
            validateFields.push('purchase_row');
            validateFields.push('lo_seat');
            validateFields.push('hi_seat');
        }

        return Object.keys(record).reduce((isValid, key) => {
            if (validateFields.some(field => field === key)) {
                isValid = isValid && !!record[key];
            }
            return isValid;
        }, true);
    }

    function validComplete() {
        let validateFields = [
            'primary_order_number',
            'primary_account',
            'primary_cost',
        ];

        let tags = record.tags;
        //Skipped for canceled event
        if (tags.toLowerCase().includes('canceled event')) return true;

        var valid = Object.keys(record).reduce((isValid, key) => {
            if (validateFields.some(field => field === key)) {
                isValid = isValid && !!record[key];
            }
            return isValid;
        }, true);

        if (!valid) {
            notify(
                <Alert severity="error">
                    Invalid Input! <br />
                    Please input informations below: <br />
                    - Primary Order Number <br />
                    - Primary Account <br />
                    - Primary Cost <br />
                </Alert>
            );
        }
        return valid;
    }
    //#endregion    

    //#region SAVE ORDER
    const onChangeOrder = (name, value) => {
        record[name] = value;
        config();
    }

    function isDelayDateInvalid() {
        var delayDate = dayjs(record?.delay_date);
        var inhandDate = dayjs(record?.in_hand_date);
        return delayDate.isAfter(inhandDate);
    }

    const onSaveOrder = async () => {
        function changeActor(originalVal, newChange) {
            if (!originalVal || originalVal.toLowerCase().indexOf('auto') > -1)
                record.shipper = newChange;
        }

        var primaryOrderID = record.primary_order_number;
        var match = /^\s*([0-9]+-[0-9]+\/[A-Z0-9]{3}).*/.exec(primaryOrderID);
        if (match != null && match[0] != match[1]) {
            notify('ERROR: Invalid Primary Order ID', { type: 'error' });
            return;
        }

        if (record.internal_status == STATUS_ENUM.Completed) {
            changeActor(record.shipper, currUser);
        } else if (record.internal_status == STATUS_ENUM.PendingShipment) {
            changeActor(record.processor, currUser);
        }

        let displayed = validateDialogFixed('saveOrder');//"saveOrder": specific validating order when click SAVE button

        var internal_status = record.internal_status;
        if (internal_status == STATUS_ENUM.PendingShipment) {
            if (!validPurchase()) {
                notify(
                    <Alert severity="error">
                        <h4>Invalid Input!</h4><br />
                        Please input informations below: <br />
                        - Primary Order Number <br />
                        - Primary Account <br />
                        - Primary Cost <br />
                        - Section <br />
                        - Row <br />
                        - Quantity <br />
                        - Low Seat <br />
                        - High Seat <br />
                    </Alert>
                )
                return;
            }
        }
        else if (internal_status == STATUS_ENUM.Completed) {
            if (!validComplete()) {
                return;
            }
        }

        if (isDelayDateInvalid()) {
            notify(
                <Alert severity="error">
                    <h4>Invalid Input!</h4><br />
                    {`Please set delay date before expected date (${dayjs(record.in_hand_date).format('MM/DD/YYYY')})`}<br />
                </Alert>
            )
            return;
        }

        if (displayed) {
            setDlgState(prev => ({ ...prev, open: true }));
            return;
        }

        return updateOrder();
    }

    function updateOrder() {
        // for process tracking 
        let _requiredProcess = onConfigTrackingProcess(initialOrder, record.internal_status, notes);
        if (_requiredProcess.required) {
            setDlgState((prev) => ({
                ...prev, h_isOpen: true, h_isEnable: true,
                callback: (data) => {
                    if (data) onChangeOrder('orderProcessTracking', data);
                    saveOrder();
                }
            }));
            return;
        }
        // for tracking mapping
        let _requiredMapping = onConfigTrackingMapping(record, initialOrder.tags);
        if (_requiredMapping) {
            setDlgState((prev) => ({
                ...prev, m_isOpen: true, m_data: { oldTags: initialOrder.tags },
                callback: (data) => {
                    record.mapping_error = data;
                    saveOrder();
                }
            }));
            return;
        }

        saveOrder();
    }

    function saveOrder() {
        console.log("Begin save Order");
        return dataProvider.postData(vendor.controller, 'Update', record)
            .then(res => {
                if (res.data) {
                    if (res.data.msg) {
                        notify(`${response.success ? 'WARNING' : 'ERROR'}: ${response.msg}`, { type: `${response.success ? 'warning' : 'error'}` })
                        if (res.data.msg.includes('Someone has changed the order')) {
                            setAlertData((prev) => ({ ...prev, isAlert: true, showOrderLastChangedBy: undefined }));
                        }
                    }
                    else if (res.data.success == true)
                        notify(`Order saved successfully`, { type: 'success' });
                    if (res.data.success) {
                        let response = res.data;
                        record.str_utc_last_updated_on = response.order.str_utc_last_updated_on;

                        var notes = response.order.order_notes || [];
                        record.order_notes = notes.reduce(function (prev, cur) { if (prev.length < 3 && cur.note_type == 0) prev.push(cur); return prev; }, []);
                        record.order_fullnotes = notes;
                        notify('Order saved successfully', { type: 'success' });
                        bus.broadcast(bus_events.order_saved, record);
                    } else {
                    }
                }
            })
            .finally(_ => {
                if (dlgState.control == 'cad2usd') {
                    loadNotes();
                    notify('Cost was converted to USD from CAD', { type: 'success' });
                }
                setDlgState(prev => ({ ...prev, control: '' }));
                setSaving(false);
            });
    }

    const handleValidateConfirm = () => {
        setDlgState(prev => ({ ...prev, open: false, content: [] }));
        switch (dlgState.control) {
            case 'saveOrder':
                let isChangeDeliveryMethod = dlgState.content.some(x => x.props?.children?.toLowerCase().includes('send an email to portal'));
                if (isChangeDeliveryMethod) {
                    record.allowNotify = dlgState.sendEmail;
                }
                return updateOrder();
            case 'shipping':
                setDlgState(prev => ({ ...prev, openShipping: true }));
                break;
            default:
                record.cad2usdNote = 'CAD ' + initialOrder.primary_cost + ' to USD ' + record.primary_cost;//send cad2usdNote to ordernotedetails => start add note
                onSaveOrder();
                break;
        }
    }

    const handleCADtoUSD = (props) => {
        var hasNotesMentionedCAD = notes.some(note => note.post.toLowerCase().includes('cad'));

        if (hasNotesMentionedCAD && !confirm('In notes of this order already mentioned about CAD. Are you sure to continue?')) {
            return;
        }

        var originalVal = initialOrder.primary_cost;
        var val = record.primary_cost
        if (originalVal != val) {
            return alert('The price was already converted to USD');
        }

        var cost = parseFloat(val) * USD2CAD;
        if (cost > 0) {
            record.primary_cost = cost;
            setDlgState(prev => ({
                ...prev, open: true, control: 'cad2usd', content: [...prev.content,
                <span>Do you want to save the converted amount?</span>
                ]
            }))
        }
    }
    //#endregion 

    const MuiFormLabel = (props) => {
        const { text } = props;
        return (
            <span className="MuiFormLabel-root control-label">{text}</span>
        );
    };

    const AlertOrderChanged = (props) => {
        let { by } = props;
        by = showOrderLastChangedBy ?? by ?? 'Someone else';
        return (
            <div name="refresh-after-order-changed" class="alert alert-info">
                <a onClick={reload([record.id])}><b>{by}</b> has just updated the order, Please click here to discard your changes, and keep remote ones or click on the button Save to discard remote changes, and keep your changes. Thank you!
                </a>
            </div>
        );
    }

    return (
        <>
            {record &&
                <Grid container className="Form-Order" spacing={1} sx={{ minHeight: '400px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {isAlert && <AlertOrderChanged by={record.processor} />}
                        </Grid>
                    </Grid>
                    {/* LEFT GROUP */}
                    <Grid item xs={4} style={{ pointerEvents: isLoading ? 'none' : '', opacity: isLoading ? '0.5' : '' }}>
                        {isLoading && <LinearProgress color='secondary' />}
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Order ID' />
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} sx={{ alignContent: 'center' }}>
                                        <Typography>{record?.order_number}</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Button variant="outlined" size="small" style={{ marginRight: '3px' }} href={`${location.origin}/Listings/?ListingId=${record?.ticket_id}&account_id_eq=${record?.vivid_account_id}&action=&refreshAgo=&is_shown_eq=null`} target="_blank">Listing</Button>
                                        <Button variant="outlined" size="small" style={{ marginRight: '3px' }} href={`${location.origin}/ListingsMapping/?TicketOd=${record?.ticket_id}&marketId=${record?.market_id}`} target="_blank">Mapping</Button>
                                        <Button variant="outlined" size="small" style={{ marginRight: '3px' }} href={`${location.origin}/EventDelivery/?SiteId=${record?.event_site_id}`} target="_blank">EDT</Button>
                                        <Button variant="outlined" size="small" style={{ marginRight: '3px' }} onClick={() => setDlgState((prev) => ({ ...prev, dmq_isOpen: true }))}>DMQ</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Primary Order ID' />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField size="small" hiddenLabel margin="normal" variant="standard" value={record?.primary_order_number} onChange={(props) => onChangeOrder('primary_order_number', props.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Penalty Cost' />
                            </Grid>
                            <Grid item xs={8} sx={{ marginBottom: '10px' }}>
                                <MNumber decimal={2} value={record?.penalty_cost} onChange={(value) => onChangeOrder('penalty_cost', value)} size="small" hiddenLabel margin="dense" variant="filled" startAdornment={<InputAdornment position="start">$</InputAdornment>} placeholder="Penalty cost" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Processor' />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{record?.processor}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Date Expected' />
                            </Grid>
                            <Grid item xs={8} className="datepicker-padding">
                                <MDatePicker value={record?.in_hand_date} onChange={(props) => onChangeOrder('in_hand_date', props.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Tags' />
                            </Grid>
                            <Grid item xs={8}>
                                <MAutoComplete id="mautocomplete" name='tags' variant={'outlined'} defaultValue={record?.tags.split(',').filter(x => x)}
                                    choices={tags} getStyles={getTagsColor} onChange={(values) => onChangeOrder('tags', values.join(','))} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Section' />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField value={record?.section} disabled size="small" hiddenLabel margin="normal" variant="filled" onChange={(props) => onChangeOrder('section', props.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Section' />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField value={record?.purchase_section} name="section" onChange={(props) => onChangeOrder('purchase_section', props.target.value)} size="small" hiddenLabel margin="normal" variant="standard" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Delay Date' />
                            </Grid>
                            <Grid item xs={8} className="datepicker-padding">
                                <MDatePicker label={'Delay Date'} value={record?.delay_date} onChange={(formatedValue) => onChangeOrder('delay_date', formatedValue)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Delivery Method' />
                            </Grid>
                            <Grid item xs={8} className="mselect-padding">
                                <MSelect className='mselect-small' choices={DeliveryMethodEnum.map(x => ({ ...x, id: parseInt(x.id) }))} value={record?.delivery_method} onChange={(value) => onChangeOrder('delivery_method', value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Price Level' />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField value={record?.pricelevel} size="small" hiddenLabel margin="normal" variant="filled" disabled onChange={(props) => onChangeOrder('pricelevel', props.target.value)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* CENTER GROUP */}
                    <Grid item xs={4} style={{ pointerEvents: isLoading ? 'none' : '', opacity: isLoading ? '0.5' : '' }}>
                        {isLoading && <LinearProgress color='secondary' />}
                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Account' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography marginTop={'3px'} marginBottom={'3px'}>{accounts.find(acc => acc.id == record.vivid_account_id)?.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ paddingTop: '8px' }}>
                            <Grid item xs={6} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Primary Account' />
                            </Grid>
                            <Grid item container xs={6}>
                                <Grid item xs={10}>
                                    <TextField value={record?.primary_account} size="small" hiddenLabel margin="normal" variant="standard" onChange={(props) => onChangeOrder('primary_account', props.target.value)} />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton title="Suggest" size="small" sx={{ marginRight: '3px', borderRadius: '4px' }} onClick={() => setDlgState((prev) => ({ ...prev, s_isOpen: true }))}><SavedSearchOutlined /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Primary Cost' />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: '10px' }}>
                                <MNumber decimal={2} value={record?.primary_cost} onChange={(value) => onChangeOrder('primary_cost', value)} size="small" hiddenLabel margin="dense" variant="standard" startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Payment' />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{ color: 'gray' }}>{record?.payment_status || 'NA'}</Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Shipper' />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{ color: 'gray' }}>{record?.shipper || 'Shipper'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='CB Date' />
                            </Grid>
                            <Grid item xs={4} className="datepicker-padding">
                                <MDatePicker label={'Check back date'} value={record?.checkback_date} onChange={(props) => onChangeOrder('checkback_date', props.target.value)} />
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Status' />
                            </Grid>
                            <Grid item xs={4} sx={{ alignContent: 'center' }} className="mselect-padding">
                                <MSelect className='mselect-small' name="internal_status" choices={allowEditStatuses} value={record?.internal_status} onChange={(value) => onChangeOrder('internal_status', value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Unit Price' />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField value={record?.unit_list_price} onChange={(props) => onChangeOrder('unit_list_price', props.target.value)} sx={{ color: 'white' }} disabled hiddenLabel size="small" margin="normal" variant="filled" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }} />
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Order Total' />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField value={record?.order_total} onChange={(props) => onChangeOrder('order_total', props.target.value)} disabled size="small" hiddenLabel margin="normal" variant="filled" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Quantity' />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField value={record?.quantity} onChange={(props) => onChangeOrder('quantity', props.target.value)} disabled size="small" hiddenLabel margin="normal" variant="filled" />
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Row' />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField value={record?.row} onChange={(props) => onChangeOrder('row', props.target.value)} disabled size="small" hiddenLabel margin="normal" variant="filled" />
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Seat' />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField value={record?.seat} disabled size="small" hiddenLabel margin="normal" variant="filled" onChange={(props) => onChangeOrder('seat', props.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Quantity' />
                            </Grid>
                            <Grid item xs={2}>
                                <MNumber decimal={2} value={record?.purchase_quantity} onChange={(value) => onChangeOrder('purchase_quantity', value)} size="small" hiddenLabel margin="normal" variant="standard" />
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Row' />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField value={record?.purchase_row} onChange={(props) => onChangeOrder('purchase_row', props.target.value)} size="small" hiddenLabel margin="normal" variant="standard" />
                            </Grid>
                            <Grid item xs={2} >
                                <MNumber decimal={2} value={record?.lo_seat} onChange={(value) => onChangeOrder('lo_seat', value)} size="small" hiddenLabel margin="normal" variant="standard" placeholder="Lo Seat" />
                            </Grid>
                            <Grid item xs={2}>
                                <MNumber decimal={2} value={record?.hi_seat} onChange={(value) => onChangeOrder('hi_seat', value)} size="small" hiddenLabel margin="normal" variant="standard" placeholder="Hi Seat" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '-5px' }}>
                            {dlgState.d_isEnable && (
                                <>
                                    <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                        <MuiFormLabel text='Delivery delay' />
                                    </Grid>
                                    {[1, 2, 3, 7, 10].map((e) => {
                                        let option = e == 1 ? ' day' : ' days';
                                        return (
                                            <Grid item xs={2}>
                                                <Button variant="outlined" size="small" onClick={() => onClickDeliveryDelay(e, 'd_isOpen')}>{e + option}</Button>
                                            </Grid>
                                        );
                                    })}
                                </>
                            )}
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '-5px' }}>
                            <Grid item xs={4}>
                                {forceRender.isAvailableUnshipped && <OrderShipping notify={notify} vendor={vendor} validate={validateDialogFixed}
                                    openShipping={dlgState.openShipping} closePopup={() => setDlgState(prev => ({ ...prev, openShipping: false }))} />}
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='LTP' />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField size="small" onChange={(props) => onChangeOrder('link_to_print_tickets', props.target.value)} value={record?.link_to_print_tickets} hiddenLabel margin="normal" variant="standard" placeholder="Link to Print Tickets" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sx={{ textAlign: "right", alignContent: 'center' }}>
                                <MuiFormLabel text='Process Tracking' />
                            </Grid>
                            <Grid item xs={6}>
                                {/* https://analysis.songtek.net/ProcessTrackingReport?event=Hamilton&venue=Eccles%20Theater%20-%20Salt%20Lake%20City%20-%20Salt%20Lake%20City,%20UT&Type_eq=arttix */}
                                <IconButton size="small" sx={{ backgroundColor: '#5cc0de', borderRadius: '4px' }} target="_blank" title="Lookup history"
                                    href={`${location.origin}/ProcessTrackingReport?utc_Posted_On=["${dayjs().add(-2, 'M').format('YYYY-MM-DD')}"]&venue=${encodeURIComponent(record?.venue).replace(/\./g, '%2E')}&type_eq=${record?.event_market?.toLowerCase()}`}
                                >
                                    <SearchIcon />
                                </IconButton>
                                {dlgState.h_isEnable && <IconButton title="Tracking" size="small" sx={{ backgroundColor: 'orange', borderRadius: '4px', marginLeft: '3px', }}
                                    onClick={() => setDlgState((prev) => ({ ...prev, h_isOpen: true, h_isSaveOrder: false }))}>
                                    <Storage />
                                </IconButton>
                                }
                                {record?.isMappingError && <IconButton title="Mapping Error" size="small"
                                    sx={{
                                        borderRadius: '4px',
                                        background: '#d9534f', '&:hover': {
                                            backgroundColor: '#c9302c',
                                        },
                                        marginLeft: '3px'
                                    }}
                                    target="_blank"
                                    href={`${location.origin}/MappingErrorReport?order_number_eq=${record?.order_number}`} >
                                    <FmdBadOutlined />
                                </IconButton>}
                            </Grid>
                        </Grid>
                        <Grid container spacing={0.5} sx={{ marginTop: '4px' }} justifyContent={"flex-end"}>
                            <Grid item>
                                {forceRender.isWillCall && <MoreDetailPopup {...sharedDialogProps} />}
                            </Grid>
                            <Grid item>
                                <Button onClick={handleCADtoUSD} variant="outlined" size="small" sx={{ minWidth: 'auto' }} ><MuiFormLabel text='$&nbsp;' /> {'CAD -> USD'}</Button>
                            </Grid>
                            <Grid item>
                                <Button className="dark-primary-button"
                                    variant="contained"
                                    size="small"
                                    disabled={!initialOrder}
                                    startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                                    onClick={() => {
                                        // turn on save Order for tracking process
                                        setSaving(true);
                                        setDlgState((prev) => ({ ...prev, h_isSaveOrder: true }));
                                        onSaveOrder();
                                    }}>
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* RIGHT GROUP */}
                    <Grid item xs={4} style={{ pointerEvents: !notes ? 'none' : '', opacity: !notes ? '0.5' : '' }}>
                        <OrderNotesDetails
                            vendor={vendor}
                            dataProvider={dataProvider}
                            order={record}
                            notes={notes}
                            customer={customer}
                            cad2usdNote={record.cad2usdNote}
                            callback={(data) => {
                                console.log("callback", data);
                                loadNotes();
                            }}
                        />
                    </Grid>
                </Grid>
            }
            <DeliveryDelayPopup {...sharedDialogProps} />
            <SuggestAccountsPopup {...sharedDialogProps} onChange={(value) => onChangeOrder('primary_account', value)} />
            <OrderProcessTrackingPopup {...sharedDialogProps} callback={(data) => onChangeOrder('orderProcessTracking', data)} />
            <TrackingMappingPopup {...sharedDialogProps} />
            <DMQPopup {...sharedDialogProps} />
            <Confirm isOpen={dlgState.open}
                aria-label="open"
                title={['shipping', 'saveOrder'].some(x => x == dlgState.control) ? 'WARNING! DO YOU WANT SAVE ANY WAY?' : ''}
                content={dlgState.content}
                onClose={(e) => onCloseDialog(e, 'open')}
                onConfirm={handleValidateConfirm}
                confirmColor="primary" />
        </>
    );
});

export default OrderDetails;